/**
 * Created by Miroslav on 17/11/2016.
 */

if (typeof window !== 'undefined' && !window.hasOwnProperty('uString')) {
    window['uString'] = {
        translations: {},
        addTranslations: function (translations) {
            for (let translation in translations) {
                this.translations[translation] = translations[translation];
            }
        },
        t: function (message) {
            if (this.translations.hasOwnProperty(message)) {
                return this.translations[message];
            }
            return message;
        },
        valueOrNullIfEmpty: function (value) {
            return value === '' ? null : value;
        },
        valueOrEmptyIfNull: function (value) {
            return value === null ? '' : value;
        },
        isNullOrEmpty: function (value) {
            return value === null || value === '';
        },
        isNullOrWhitespace: function (value) {
            return value === null || value === '' || (typeof value === 'string' && value.trim() === '');
        },
        uuidv4: function () {
            return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
                (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
            );
        }
    };
}